<template>
    <div id="Epg" class="container">
        <div class="epg-main-container">
            <div class="epg-header-container" v-if="headerContainer">
                <div class="main-title">
                    <h1 class="cat-title font-24">{{ $t("Program Guide") }}</h1>
                </div>
                <div class="date-time">
                    <h1 class="cat-title font-24">{{ upDateTime.time }}</h1>
                    <span>{{ upDateTime.date }}</span>
                </div>
            </div>
            <div class="callender-conatiner">
                <CallenderContainer @actSelectDate="actSelectDate"></CallenderContainer>
            </div>
            <div class="data-container">
                <div class="epg-channel-container box-shadow" v-if="channelCard">
                    <ChannelContainer :channelsData="channelsData" @actSelectedChannel="actSelectedChannel"
                        v-if="channelsData" @actPagination="actPagination" :showPaginationLoader="showPaginationLoader">
                    </ChannelContainer>
                    <div v-else class="epg-channel-wrapper load-wrapper">
                        <WaveLoader :loaderHeight="loaderHeight" />
                    </div>
                </div>
                <div class="epg-programme-main-container" :class="[!channelCard ? 'epg-programme-100' : '']"
                    id="epg-programme-container-id">
                    <ProgramContainer :activeProgramData="activeProgramData" :activeChannelData="activeChannelData"
                        :selectedChannelIndex="selectedChannelIndex" @actSelectedProgram="actSelectedProgram" />
                </div>
            </div>
        </div>
        <div v-if="loading" class="main-loader-layer">
            <div class="inner-loader-layer">
                <DotFlashing />
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { eventBus } from "@/eventBus";
import moment from "moment";
import PlaybackUtilities from "@/mixins/playbackUtilities.js";

export default {
    name: 'EpgContainer',
    props: {
        headerContainer: {
            type: [Boolean, String, Object, Number],
            default: null,
        },
        channelCard: {
            type: [Boolean, String, Object, Number],
            default: null,
        },
        channelno: {
            type: [Boolean, String, Object, Number],
            default: null,
        }
    },
    components: {
        WaveLoader: () => import(/* webpackChunkName: "waveLoader" */ "@/components/Loaders/WaveLoader.vue"),
        ProgramContainer: () => import(/* webpackChunkName: "programContainer" */ "@/components/Epg/EpgProgramContainer.vue"),
        CallenderContainer: () => import(/* webpackChunkName: "callenderContainer" */ "@/components/Epg/CallenderContainer.vue"),
        ChannelContainer: () => import(/* webpackChunkName: "channelContainer" */ "@/components/Epg/ChannelContainer.vue"),
        DotFlashing: () => import(/* webpackChunkName: "dotFlashing" */ "@/components/Loaders/DotFlashing.vue")
    },
    data() {
        return {
            finalData: null,
            channelsData: null,
            programsData: null,
            upDateTime: {
                date: '00-00-0000',
                time: '0:00'
            },
            weekArray: [],
            selectedChannelIndex: 0,
            selectedDateIndex: 1,
            loaderHeight: "2.5rem",
            localDisplayLang: null,
            loading: false,
            placeholder_lands: require('@/assets/placeholder-lands.svg'),
            activeProgramData: null,
            activeChannelData: null,
            pageNum: 1,
            totalCount: 0,
            showPaginationLoader: true,
            headerContainerEnable: true,
            ChannelContainerEnable: true
        }
    },
    computed: {
        ...mapGetters(["subscriberid", "profileid", "selectedChannel", "selectedDate"]),
    },
    created() {
        this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");
    },
    mounted() {
        this.headerContainerEnable = this.headerContainer;
        this.ChannelContainerEnable = this.channelCard;
        let date = (new Date()).toISOString().split('T')[0];
        this.loadScript("https://static-templates.web.app/static/xmlToJson.js")
            .then(async (data) => {
                if (data.loaded) {
                    await this.fetchEpgXmlData(date, this.pageNum);
                    // Ensure the first channel is selected if available
                    if (this.channelsData.length > 0) {
                        let selectedDate = (new Date()).toISOString().split('T')[0];
                        this.setSelectedDate(selectedDate);
                        this.actionToPickActiveProgramsOFSelectedChannelANDSelectedDate(this.channelsData[0], 0, selectedDate);
                    }
                    this.doDate();
                    setInterval(() => {
                        this.doDate();
                    }, 1000 * 60);
                }
            })
            .catch((error) => {
                console.error(error);
            });

    },
    mixins: [PlaybackUtilities],
    methods: {
        ...mapMutations(["setSelectedChannel", "setSelectedDate"]),
        ...mapActions(['mapEpgXmlData', 'getContent']),
        async fetchEpgXmlData(date, page) {
            const today = moment(); // Assuming today is January 1, 2025
            const startDate = today.subtract(3, 'days').startOf('day').utc().format("YYYY-MM-DD HH:mm:ss");
            const endDate = today.add(6 + 3, 'days').endOf('day').utc().format("YYYY-MM-DD HH:mm:ss"); // Add 6 days after resetting to today
            const payload = {
                start: startDate,
                stop: endDate,
                localDisplayLang: this.localDisplayLang,
                page,
                pagesize: 10,
                orderby: { "channelno": 'ASC' },
            };
            if (this.channelno) {
                payload.idepgchannel = "[" + this.channelno + "]"
            }

            try {
                const res = await this.mapEpgXmlData(payload);
                const parsedData = res.data ? xmlToJson.parse(res.data) : {};
                if (parsedData?.tv) {
                    this.totalCount = parsedData?.tv?.totalcount || 0;
                    const channelsData = parsedData?.tv?.totalcount == 1 ? [parsedData?.tv?.channel] : parsedData?.tv?.channel || [];
                    const programsData = parsedData?.tv?.programme || [];

                    this.channelsData = [...(this.channelsData || []), ...channelsData];
                    this.programsData = [...(this.programsData || []), ...programsData];

                    // // Ensure the first channel is selected if available
                    // if (this.channelsData.length > 0) {
                    //     let selectedDate = (new Date()).toISOString().split('T')[0];
                    //     this.actSelectedChannel(this.channelsData[0], 0, selectedDate);
                    // }
                }
            } catch (err) {
                console.error("Error fetching EPG XML data:", err);
                this.finalData = [];
            }
        },
        doDate() {
            var days = new Array("Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday");
            var months = new Array("January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December");
            let now = new Date();
            let hours = '0';
            var today = days[now.getDay()] + now.getSeconds();
            this.upDateTime.date = now.getDate() + "-" + (now.getMonth() + 1) + "-" + now.getFullYear();
            let type = ['AM', 'PM'];
            let timeType;
            let min = '00';
            if (now.getHours() > 12) {
                hours = now.getHours() - 12;
                timeType = type[1]
            } else {
                hours = now.getHours();
                timeType = type[0]
            }
            if (now.getMinutes() < 10) {
                min = '0' + now.getMinutes();
            } else {
                min = now.getMinutes();
            }
            this.upDateTime.time = hours + ":" + min + " " + timeType;
        },
        actSelectedChannel(channelInfo) {
            this.actionToPickActiveProgramsOFSelectedChannelANDSelectedDate(channelInfo.channel, channelInfo.i, this.selectedDate);
        },

        actionToPickActiveProgramsOFSelectedChannelANDSelectedDate(channel, i, selectedDate) {
            this.setSelectedChannel(channel);
            this.activeProgramData = null; // Reset active program data
            this.selectedChannelIndex = i; // Set selected channel index
            this.activeChannelData = channel; // Store active channel data
            let selectedStartDate = moment.utc(moment(selectedDate + " 00:00:00")).local().valueOf();
            let selectedEndDate = moment.utc(moment(selectedDate + " 24:00:00")).local().valueOf();
            this.sortedProgramsData(channel).then((res) => {
                if (res.length > 0) {
                    const filteredPrograms = res.filter((item) => {
                        // Ensure the channel matches
                        const isChannelMatch = item.channel === channel.id;
                        // Ensure the date falls within the selected range
                        const programStart = moment.utc(moment(item.start, "YYYYMMDDHHmmss Z")).local().valueOf();
                        const programStop = moment.utc(moment(item.stop, "YYYYMMDDHHmmss Z")).local().valueOf();
                        const isDateMatch = programStart >= selectedStartDate && programStop <= selectedEndDate;
                        return isChannelMatch && isDateMatch;
                    });
                    // Deduplicate results based on unique program attributes
                    this.activeProgramData = filteredPrograms.filter((item, index, self) => {
                        return index === self.findIndex((t) => (
                            t.channel === item.channel &&
                            t.start === item.start &&
                            t.stop === item.stop &&
                            t.title === item.title
                        ));
                    });
                } else {
                    this.activeProgramData = [];
                }
            }).catch((err) => {
                console.error("Error fetching program data:", err);
                this.activeProgramData = [];
            });

        },
        sortedProgramsData(channel) {
            let filterData = [];
            return new Promise((resolve, reject) => {
                try {
                    let singleData = this.programsData?.filter((item, i) => {
                        return item?.channel == channel?.id;
                    });
                    if (singleData) {
                        filterData = singleData?.sort((a, b) => (moment.utc(moment(a.start, "YYYYMMDDHHmmss Z")).local().valueOf()) - (moment.utc(moment(b.start, "YYYYMMDDHHmmss Z")).local().valueOf()));
                    }
                    resolve(filterData);
                } catch (error) {
                    resolve(filterData);
                }
            })
        },
        actSelectDate(date) {
            console.log(date, 'actSelectDate');
            this.setSelectedDate(date);
            // this.channelsData = null;
            this.activeProgramData = null;
            this.actionToPickActiveProgramsOFSelectedChannelANDSelectedDate(this.selectedChannel, this.selectedChannelIndex, date);
            // this.fetchEpgXmlData(date);

        },

        actSelectedProgram(condition) {
            if (condition) {
                this.loading = true;
            } else {
                this.loading = false;
            }
        },

        actUpDownArrow(type) {
            if (type === 'up') {
                // alert(type);
            } else {
                // alert(type);
            }
        },

        loadScript(src, async = true, type = "text/javascript") {
            return new Promise((resolve, reject) => {
                try {
                    const tag = document.createElement("script");
                    const container = document.head || document.body;

                    tag.type = type;
                    tag.async = async;
                    tag.src = src;

                    tag.addEventListener("load", () => {
                        resolve({ loaded: true, error: false });
                    });

                    tag.addEventListener("error", () => {
                reject({
                loaded: false,
                error: true,
                message: `Failed to load script with src ${src}`,
                });
            });

            container.appendChild(tag);
            } catch (error) {
            reject(error);
            }
        });
        },

        getImageSrc(src) {
            return src ? `${src}` : this.placeholder_lands;
        },

        actPagination() {
            let date = (new Date()).toISOString().split('T')[0];
            if(this.totalCount > this.channelsData.length) {
                this.pageNum += 1;
                this.fetchEpgXmlData(date, this.pageNum);
            } else {
                this.showPaginationLoader = false;
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";
@import "@/sass/_components.scss";
@import "./Epg.scss";

.active {
    background: $primary-btn-color;
    color: #FFFFFF !important;
}

.user-img,
.cast-name {
    pointer-events: none;
}

.user-item:hover:not(.active) {
    background-color: black;
}

// .d-none {
//     display: none;
// }

.main-loader-layer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0%;
    left: 0%;
    // transform: translate(-50%, -50%);
    background: rgba(49, 49, 49, 0.726);
    border: 0.1rem #262626 solid;
    width: 100vw;
    height: 100vh;
}

.inner-loader-layer {
    background: rgba(8, 8, 8, 0.726);
    border: 0.1rem #262626 solid;
    width: 10rem;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.epg-programme-100 {
    width: 100% !important;

}
</style>